import { hillo } from 'hillo'

export async function checkElectronicBillingStatus () {
  return (await hillo.get('Complex.php?op=checkAadenPoint', {})).content.enabled
}

export async function enableElectronicBilling () {
  return await hillo.get('Complex.php?op=enableAadenPoint', {})
}

export async function disableElectronicBillingStatus () {
  return await hillo.get('Complex.php?op=disableAadenPoint', {})
}

export async function getAllElectronicBillingSetting () {
  return (await hillo.get('Complex.php?op=getAllAadenPointSettings', {})).content
}

export async function updateAllElectronicBillingSetting (items) {
  return (await hillo.post('Complex.php?op=updateAadenPointSettings', {
    settings: JSON.stringify(items)
  }))
}
